import verEventoBlanco from "../../assets/img/verEventoBlanco.svg"

const Festival = () => {
    return (
        <>
            <section
                className="page-section d-flex justify-content-center"
                id="festival"
            >
                {/*
                <div className="container-body">
                    <a href="https://www.instagram.com/trtproducciones/" target={"_blank"} rel={"noreferrer"}>
                        <img
                            src={verEventoBlanco}
                            width={60}
                            height={60}
                            alt={verEventoBlanco}
                            id="botonFestival"
                        />
                    </a>
                </div>
                */}
            </section>
        </>
    )
}

export default Festival

import ModalCustom from "./Modal"
import { useState } from "react"

import ciro from "../../assets/images/fotosAColor/ciroColor.jpg"
import cruzandoElCharco from "../../assets/images/fotosAColor/cruzandoColor.jpg"
import fundamentalistasFondo from "../../assets/images/fotosAColor/fundamentalistasColor.jpg"
import guasones from "../../assets/images/fotosAColor/guasonesColor.jpg"
import elmato from "../../assets/images/fotosAColor/elMatoColor.jpg"
import lostotora from "../../assets/images/fotosAColor/losTotoraColor.png"
import verEventoBlanco from "../../assets/img/verEventoBlanco.svg"
import verEventoNegro from "../../assets/img/verEventoNegro.svg"

const Eventos = () => {
    const [show, setShow] = useState(false);
    const [cruzando, setCruzando] = useState(false);
    const [fundamentalistas, setFundamentalistas] = useState(false);

    const handleClose = () => {
        setShow(false);
        setCruzando(false);
        setFundamentalistas(false);
    }
    const handleShow = () => setShow(true)

    return (
        <section className="container-fluid">
            <div className="row g-0">
                <div className="col-sm">
                    <img src={ciro} alt="ciro" className="w-100" onClick={setShow} id="imagenAColor"/>
                </div>
                <div className="col-sm" id="negro">
                    <div className="col-sm" id="div-fechas">
                        <h1 className="title-fecha">CIRO Y LOS PERSAS</h1>
                        <h4 className="date-fecha">
                            3/11 Barcelona Razzmatazz - SOLD OUT
                        </h4>
                        <h4 className="date-fecha">
                            4/11 Valencia Sala Repvblicca - SOLD OUT
                        </h4>
                        <h4 className="date-fecha">
                            6/11 Mallorca Es Gremi - SOLD OUT
                        </h4>
                        <h4 className="date-fecha">
                            10/11 Alicante Sala The One - SOLD OUT
                        </h4>
                        <h4 className="date-fecha">
                            11/11 Málaga Sala Paris 15 - SOLD OUT
                        </h4>
                        <h4 className="date-fecha">
                            13/11 Madrid Sala Riviera - SOLD OUT
                        </h4>  
                        
                        <button onClick={setShow}>
                            <img
                                src={verEventoBlanco}
                                width={60}
                                height={60}
                                alt={verEventoBlanco}
                            />
                        </button>
                        <ModalCustom
                            show={show}
                            handleClose={handleClose}
                            title={"ciro"}
                        />
                        
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-sm">
                    <img src={cruzandoElCharco} alt="cruzando" className="w-100" onClick={setCruzando} id="imagenAColor"/>
                </div>
                <div className="col-sm" id="blanco">
                    <div className="col-sm" id="div-fechas">
                        <h1 className="title-fecha">CRUZANDO EL CHARCO</h1>
                        <h4 className="date-fecha">
                            6/10 Madrid Sala Independance
                        </h4>
                        <h4 className="date-fecha">
                            7/10 Valencia Peter Rock Club
                        </h4>
                        <h4 className="date-fecha">
                            9/10 Barcelona Razzmatazz - SOLD OUT
                        </h4>
                        
                        <button onClick={setCruzando}>
                            <img
                                src={verEventoNegro}
                                width={60}
                                height={60}
                                alt={verEventoNegro}
                            />
                        </button>
                        <ModalCustom
                            show={cruzando}
                            handleClose={handleClose}
                            title={"cruzando"}
                        />
                        
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-sm">
                    <img src={lostotora} alt="lostotora" className="w-100" id="imagenAColor"/>
                </div>
                <div className="col-sm" id="negro">
                    <div className="col-sm" id="div-fechas">
                        <h1 className="title-fecha">LOS TOTORA</h1>
                        <h4 className="date-fecha">
                            8/9 Madrid Sala Cafe Berlin - SOLD OUT
                        </h4>
                        <h4 className="date-fecha">
                            9/9 Alicante Sala The One
                        </h4>
                        <h4 className="date-fecha">
                            17/9 Barcelona Sala Wolf - SOLD OUT
                        </h4>
                        {/*
                        <a href="https://www.instagram.com/trtproducciones/" target={"_blank"} rel={"noreferrer"}>
                        <img
                            src={verEventoBlanco}
                            width={60}
                            height={60}
                            alt={verEventoBlanco}
                            />
                        </a>
                        */}
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-sm">
                    <img src={elmato} alt="elmato" className="w-100" id="imagenAColor"/>
                </div>
                <div className="col-sm" id="blanco">
                    <div className="col-sm" id="div-fechas">
                        <h1 className="title-fecha">EL MATO A UN POLICIA MOTORIZADO</h1>
                        <h4 className="date-fecha">
                            2/7 Mallorca Es Gremi
                        </h4>
                        <h4 className="date-fecha">
                            10/7 Málaga Sala Trinchera
                        </h4>
                        {/*
                        <a href="https://www.instagram.com/trtproducciones/" target={"_blank"} rel={"noreferrer"}>
                        <img
                            src={verEventoNegro}
                            width={60}
                            height={60}
                            alt={verEventoNegro}
                            />
                        </a>
                        */}
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-sm">
                    <img
                        src={guasones}
                        alt="guasones"
                        className="w-100"
                        id="imagenAColor"
                    />
                </div>
                <div className="col-sm" id="negro">
                    <div className="col-sm" id="div-fechas">
                    <h1 className="title-fecha">GUASONES</h1>
                        <h4 className="date-fecha">
                            26/5 Barcelona Razzmatazz - SOLD OUT
                        </h4>
                        <h4 className="date-fecha">
                            27/5 Mallorca Es Gremi
                        </h4>
                        <h4 className="date-fecha">
                            28/5 Valencia Madison Club
                        </h4>
                        <h4 className="date-fecha">
                            3/6 Málaga Sala Trinchera
                        </h4>
                        <h4 className="date-fecha">
                            4/6 Madrid Sala Independance
                        </h4>
                        {/*
                        <a href="https://www.instagram.com/trtproducciones/" target={"_blank"} rel={"noreferrer"}>
                        <img
                            src={verEventoBlanco}
                            width={60}
                            height={60}
                            alt={verEventoBlanco}
                            />
                        </a>
                        */}
                    </div>
                </div>
            </div>
            <div className="row g-0">
                <div className="col-sm">
                    <img src={fundamentalistasFondo} alt="fundamentalistas" className="w-100" onClick={setFundamentalistas} id="imagenAColor"/>
                </div>
                <div className="col-sm" id="blanco">
                    <div className="col-sm" id="div-fechas">
                        <h1 className="title-fecha">LOS FUNDAMENTALISTAS DEL AIRE ACONDICIONADO</h1>
                        <h4 className="date-fecha">31/4 Barcelona Razzmatazz - SOLD OUT</h4>
                        <h4 className="date-fecha">2/4 Mallorca Es Gremi</h4>
                        <h4 className="date-fecha">7/4 Madrid Sala Riviera</h4>
                        <h4 className="date-fecha">8/4 Valencia Sala Repvblicca - SOLD OUT</h4>
                        <h4 className="date-fecha">9/4 Málaga Sala Paris 15 - SOLD OUT</h4>

                        <button onClick={setFundamentalistas}>
                            <img
                                src={verEventoNegro}
                                width={60}
                                height={60}
                                alt={verEventoNegro}
                            />
                        </button>
                        <ModalCustom
                            show={fundamentalistas}
                            handleClose={handleClose}
                            title={"fundamentalistas"}
                        />
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Eventos

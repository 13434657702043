import { useState } from "react";
import { MediaQueries } from "../../const/BreakpointsMediaQueries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import verEventoBlanco from "../../assets/img/comprarTicketsBlanco.svg";
import verEventoNegro from "../../assets/img/comprarTicketsNegro.svg";
import eventsData from "./../events.json";

const eventos = eventsData;

const Fechas = () => {
    const [show, setShow] = useState("");
    const { isTabletOrMobile } = MediaQueries();

    const handleClick = () => {
        setShow(!show);
    };

    return (
        <section className="flex section-fechas">
            {eventos.map((evento, index) => {
                const imageUrl = evento.src;
                return (
                    <div className="row row-fechas g-0" key={index}>
                        <div className="col-sm">
                            <img
                                src={evento.src}
                                alt={evento.alt}
                                className="w-100"
                                id="imagenAColor"
                            />
                        </div>
                        <div
                            id={index % 2 === 0 ? "blanco" : "negro"}
                            className="col-sm linea-flecha"
                        >
                            <div className="col-sm" id="div-fechas">
                                <h1 className="title-fecha">{evento.titulo}</h1>
                                <h2 className="title-fecha">{evento.lugar}</h2>
                                <h4 className="date-fecha">{evento.fecha}</h4>
                                <h4 className="date-fecha">{evento.venue}</h4>
                                {evento.url !== "" ? (
                                    <a
                                        href={evento.url}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <img
                                            src={index % 2 === 0 ? verEventoNegro : verEventoBlanco}
                                            width={70}
                                            height={70}
                                            alt="verEvento"
                                            className="prueba"
                                        />
                                    </a>
                                ) : null}
                            </div>
                        </div>
                    </div>
                );
            })}
        </section>
    );
};

export default Fechas;
import { MediaQueries } from "../../const/BreakpointsMediaQueries";
import eventsData from "./../events.json";
import ModalCustomIframe from "./Modal";
import { useState, useEffect } from "react";

const Inicio = () => {
	const [openModalIframe, setOpenModalIframe] = useState(false);
	const { isTabletOrMobile } = MediaQueries();

	const events = eventsData;

	useEffect(() => {
	}, [openModalIframe]);

	const handleOpenModalIframe = (srcID, id) => {
		setOpenModalIframe(true);
		setModalProps({ srcID, id });
	};

	const handleClose = () => {
		setOpenModalIframe(false);
		setModalProps({ srcID: "", id: "" });
	};

	const [modalProps, setModalProps] = useState({
		srcID: "",
		id: ""
	});

	return (
		<section className='page-section' id='inicio'>
			<div className='container'>
				<div className='row justify-content-center'>
					{events.map((event, index) => (
						<div
							className='d-flex flex-column px-3 align-items-center'
							id='flyer'
							key={index}>
							<img
								src={event.src}
								alt={event.alt}
								width={350}
								height={350}
							/>
							{event.url !== "" ? (
								<button
									type='button'
									className='btn'
									id='boton-inicio'
									onClick={() =>
										(window.location.href =
											event.url)
									}>
									Comprar
									tickets
								</button>
							) : null}
							<hr />
						</div>
					))}
				</div>
			</div>
		</section>
	);
};

export default Inicio;
